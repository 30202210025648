import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { AssignmentService } from '@features/assignment/assignment.service';
import { DrillService } from '@features/drill/drill.service';
import { CoachService } from '@shared/services/coach.service';
import { HeadingComponent } from '@shared/ui/heading/heading.component';
import { ProgressCard, ProgressCardComponent } from '@shared/ui/progress-card/progress-card.component';
import { UnderConstructionComponent } from '@shared/ui/under-construction/under-construction.component';

@Component({
  selector: 'coach-dashboard',
  host: { class: 'content-wrapper' },
  imports: [ProgressCardComponent, UnderConstructionComponent, HeadingComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
  players = inject(CoachService).players;
  drills = inject(DrillService).drills;
  assignments = inject(AssignmentService).assignments;

  progress = computed<ProgressCard[]>(() => [
    {
      title: 'players.title',
      icon: 'player_card',
      navigation: '/players',
      progress: `${this.players.value()?.length}`,
      loading: this.players.isLoading(),
    },
    {
      title: 'drills.title',
      icon: 'coach_drill',
      navigation: '/drills',
      progress: `${this.drills.value()?.length}`,
      loading: this.drills.isLoading(),
    },
    {
      title: 'assignments.title',
      icon: 'coach_assignments',
      navigation: '/assignments',
      progress: `${this.assignments.value()?.length}`,
      loading: this.assignments.isLoading(),
    },
  ]);
}
