<coach-heading text="navigation.home" />

<div class="progress-container">
  @for (card of progress(); track card.title) {
    <coach-progress-card
      [title]="card.title"
      [icon]="card.icon"
      [progress]="card.progress"
      [navigation]="card.navigation"
      [loading]="card.loading" />
  }
</div>

<coach-under-construction />
