import { inject, Injectable, linkedSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, pairwise } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  #router = inject(Router);

  #previousUrl = toSignal(
    this.#router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      pairwise(),
      map(([prev, next]) => {
        if (this.#router.getCurrentNavigation()?.extras?.info === 'ignore') return;
        const prevUrl = prev?.urlAfterRedirects;
        const nextUrl = next?.urlAfterRedirects;
        const routesBlockedFromStackingNavigations = ['settings', 'admin'];
        for (const route of routesBlockedFromStackingNavigations) {
          if (prevUrl.includes(route) && nextUrl.includes(route)) return;
        }
        if (prevUrl.includes('login')) return;
        return prevUrl;
      })
    )
  );

  previousUrls = linkedSignal<string | undefined, string[]>({
    source: this.#previousUrl,
    computation: (url, previous) => {
      const p = previous?.value ?? [];
      if (!url) return [...p];
      return [...p, url];
    },
  });

  back() {
    this.#router.navigate([this.previousUrls().pop()], { info: 'ignore' });
  }
}
