<button
  coach-button
  [color]="auth.viewAsRole() ? 'primary' : color()"
  [icon]="toggle() ? 'eye_hidden' : 'eye'"
  (click)="toggle.set(!toggle())"></button>
@if (toggle()) {
  @for (role of roles; track $index) {
    <button
      coach-button
      class="button"
      [selected]="role === auth.viewAsRole()"
      (click)="viewAs(role)"
      [text]="role.toLocaleUpperCase()"
      size="small"
      color="black"></button>
  }
  @if (auth.viewAsRole()) {
    <button coach-button (click)="viewAs(null)" icon="x" size="small" variant="text"></button>
  }
}
