<div class="login-container" *transloco="let t; read: 'invite.new'">
  <div class="login">
    <img class="logo" src="assets/images/coach-logo-horizontal-primary.svg" alt="logo" />
    <div class="form">
      <div class="invite-container">
        <div>
          <h1 class="title-1">{{ t('title') }}</h1>
          <h3 class="title-3">{{ t('header') }}</h3>
        </div>
        <p>{{ t('info') }}</p>
        <div class="footer">
          <button coach-button (click)="logIn()" text="start" [icon]="'arrow_right'" [iconPosition]="'right'"></button>
        </div>
      </div>
    </div>
    <coach-language-picker class="picker" [menuConfig]="{ anchorPoint: ['top', 'left'], animation: 'from-bottom' }" />
  </div>
  <div class="image-container">
    <img class="image" src="assets/images/login-image-1.jpg" alt="login-image" />
    <img class="image" src="assets/images/login-image-2.jpg" alt="login-image" />
  </div>
</div>
