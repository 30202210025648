import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { RoleSwitcherComponent } from '@auth/ui/role-switcher/role-switcher.component';
import { DeviceService } from '@shared/services/device.service';
import { HighlightService } from '@shared/services/highlight.service';
import { NavigationService } from '@shared/services/navigation.service';
import { UtilityService } from '@shared/services/utility.service';
import { ActiveUsersDropdownComponent } from '@shared/ui/active-users-dropdown/active-users-dropdown.component';
import { ButtonColor, ButtonComponent } from '@shared/ui/button/button.component';
import { CircularSpinnerComponent } from '@shared/ui/circular-spinner/circular-spinner.component';
import { ProfileDropdownComponent } from '@shared/ui/profile-dropdown/profile-dropdown.component';

@Component({
  selector: 'coach-top-bar',
  imports: [
    ButtonComponent,
    ActiveUsersDropdownComponent,
    ProfileDropdownComponent,
    RoleSwitcherComponent,
    CircularSpinnerComponent,
    RouterLink,
  ],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss',
  host: { '[class.full-screen]': 'utility.isFullscreen()' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent {
  auth = inject(AuthService);
  device = inject(DeviceService);
  utility = inject(UtilityService);
  highlight = inject(HighlightService);
  navigation = inject(NavigationService);

  buttonColor = computed<ButtonColor>(() => (this.utility.isFullscreen() ? 'black' : 'white'));
}
