import { booleanAttribute, ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { Size } from '@shared/types/generic.types';
import { ButtonColor, ButtonComponent } from '../button/button.component';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: 'a[coach-link]',
  imports: [ButtonComponent],
  template: `
    <button
      coach-button
      variant="text"
      [color]="color()"
      [size]="size()"
      [icon]="external() ? 'external_link' : undefined"
      [text]="text() ?? href()"
      [disabled]="disabled()"
      iconPosition="right">
      <ng-content />
    </button>
  `,
  host: {
    '[target]': 'external() ? "_blank" : null',
  },
  styles: `
    :host {
      display: contents;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
  href = input.required<string>();
  text = input<string>();
  color = input<ButtonColor>('primary');
  size = input<Size>('medium');
  disabled = input(false, { transform: booleanAttribute });

  external = computed(() => /^https?:\/\//.test(this.href()));
}
